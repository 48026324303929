import { render, staticRenderFns } from "./Page.vue?vue&type=template&id=bb7f6dc2&scoped=true&lang=pug&"
import script from "./Page.vue?vue&type=script&lang=ts&"
export * from "./Page.vue?vue&type=script&lang=ts&"
import style0 from "./Page.vue?vue&type=style&index=0&id=bb7f6dc2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb7f6dc2",
  null
  
)

export default component.exports